*, ::before, ::after {
  box-sizing: border-box;
}

body {
  font-size: 100%;
  text-rendering: optimizeLegibility;
  background-color: white;

  font-weight: 300;
  line-height: 1.5;
  font-family: 'Lato', sans-serif;
}